<template>
    <v-dialog :value="true" overlay-opacity="0.6" max-width="90vw"  overlay-color="#000" @input="close()">
        <v-card min-height="80vh">
            <v-card-title class="py-2 dcard-title">
                <h3 class="title mb-0 text-center">
                    Attachment{{ (files.length>1) ? 's' : '' }}
                    <span v-if="files.length>1" class="pl-1">({{idx+1}}/{{ files.length }})</span>
                </h3>
                <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pb-3">
                <v-divider />
                <template v-if="file.type==1">
                    <div style="min-height:69vh;" class="d-flex justify-center align-center">
                        <div>
                            <a :href="file.file" target="_blank">
                                <v-img :src="file.file" v-if="file.type==1" :max-width="($vuetify.breakpoint.smAndDown) ? '80vw' : '95vw'" :max-height="($vuetify.breakpoint.smAndDown) ? '40vh' : '55vh'" contain class="mt-3 grey lighten-3 elevation-2" />
                            </a>
                            <p class="text-center body-2 mt-3 secondary--text px-0 mb-1 mx-auto lh-13" style="max-width: 900px;">
                                {{ file.caption || file.name }}
                            </p>
                            <p class="text-center body-2 mt-0 secondary--text px-0 lh-13 mb-0">
                                <v-btn class="mt-2" small color="primary" text outlined :href="file.file" target="_blank">{{ (file.type == 1) ? 'View Full Image' : 'View/Download' }}</v-btn>
                            </p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div style="min-height:69vh;" class="d-flex justify-center align-center">
                        <div>
                            <a :href="file.file" target="_blank">
                                <div :style="'height:200px; width:200px;'" class="rounded secondary mx-auto text-center mt-3 pt-11">
                                    <v-icon size="80" color="primary" class="mt-1">fa fa-file-pdf</v-icon>
                                    <span style="max-width:90%;" class="caption text-truncate mx-auto mt-2 d-block white--text">{{ file.name || file.file.name }}</span>
                                </div>
                            </a>
                            <p class="text-center body-2 mt-3 secondary--text px-0 mb-1 mx-auto lh-13" style="max-width: 900px;">
                                {{ file.caption || file.name }}
                            </p>
                            <p class="text-center body-2 mt-0 secondary--text px-0 lh-13 mb-0">
                                <v-btn class="mt-2" small color="primary" text outlined :href="file.file" target="_blank">{{ (file.type == 1) ? 'View Full Image' : 'View/Download' }}</v-btn>
                            </p>
                        </div>
                    </div>
                </template>
            </v-card-text>
            <v-card-actions class="card-actions">
                <v-btn color="primary" v-if="files.length>1" :disabled="idx==0" @click.native="nav('prev')"><v-icon small class="mr-2">fa fa-arrow-left</v-icon> Prev</v-btn>
                <v-spacer></v-spacer>
                <v-btn text outlined @click.native="close()">Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-if="files.length>1" :disabled="idx==files.length-1" @click.native="nav('next')">Next <v-icon small class="ml-2">fa fa-arrow-right</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {

  name: 'MediaViewer',

    props: {
        files: {
            required: true,
            type: Array
        },
        preview : {
            required: true,
            type: Number
        }
    },

    computed: {
        file(){
            return this.files[this.idx];
        }
    },
    data: () => ({
        idx: 0
    }),
    methods: {
        initData(){
            this.idx = this.preview;
            window.addEventListener("keyup", (e) => { this.checkKey(e) });
        },
        nav(dir){
            if(dir == 'next'){
                this.idx = (this.idx < this.files.length -1) ? this.idx + 1 : this.idx;
            }else{
                this.idx = (this.idx > 0) ? this.idx - 1 : 0;
            }
        },
        checkKey(e) {
            e = e || window.event;
            if (e.keyCode == '37') {
                // left arrow
                this.nav('prev');
            }
            else if (e.keyCode == '39') {
                // right arrow
                this.nav('next');
            }

        },
        close(){
          this.$emit('closed', true);
        }     
    },
    beforeDestroy(){
        window .removeEventListener('keyup', this.checkKey)
    },
    mounted(){
        this.initData();
    },
    watch: {
    }
}
</script>
